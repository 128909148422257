import React from "react"
import getValue from "get-value"
import SEO from "@components/seo"
import ContentBuilder from "@components/content-builder"
import Section from "@src/components/section"
import PostHero from "@src/components/post-hero"
import InstagramFeed from "@src/components/instagram-feed"
import RecommendedBlogs from "@src/components/recommended-blogs"
import { placeholderImageId } from "@src/utils/image"
import { useSiteSettingsStore } from "@src/hooks/useStore"

const ResourceView = ({ pageContext }) => {
  const entry = getValue(pageContext, "entry")
  const itemCategories = getValue(entry, "categories")
  const mainImageData = getValue(entry, "mainImage") || placeholderImageId
  const relatedResources = getValue(pageContext, "relatedResources")

  const { siteSettings } = useSiteSettingsStore(['siteSettings']);

  const instaPosts = siteSettings?.instaPosts;

  const {
    title,
    date,
    contentBuilder: contentBuilderBlocks,
  } = entry

  return (
    <>
      <SEO {...{ document: entry }} />
      <Section settings={{ isContainer: false, classname: '!mb-10' }}>
        <PostHero categories={itemCategories} bgImage={mainImageData} title={title} dateCreated={date} postType='resources' />
      </Section>
      <ContentBuilder blocks={contentBuilderBlocks} className={'max-w-[1020px] mx-auto !my-10'}/>
      <RecommendedBlogs blogs={relatedResources} />
      <Section settings={{ classname: 'overflow-hidden' }}>
        <InstagramFeed  instaPosts={instaPosts}/>
      </Section>
    </>
  )
}

export default ResourceView
